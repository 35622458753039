import SliderPro, { Autoplay, Buttons, Thumbnails, Arrows, Caption, Layers } from 'slider-pro-js';

document.addEventListener( 'DOMContentLoaded', () => {
  const mySlider = new SliderPro( '#my-slider', {
    addOns: [ Autoplay, Buttons, Thumbnails, Arrows, Caption,Layers ],
    arrows:true,
    autoplay: true,
    fullscreen:true,
    width: '100vw',
    height: '70vh',

  });
});